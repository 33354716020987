<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Switches">
                    <template v-slot:description>
                        <div>
                            import { HbSwitch } from 'hummingbird-aviary';
                        </div>
                        <div class="mt-2">
                            All of the same vuetify props, slots, &amp; events as "v-switch"" can be used here in "hb-switch" in addition to the custom ones which are listed at the bottom of this page. For the list of the vuetify available props, slots, &amp; events, go to the vuetify documentation link for "v-switch" here: <hb-link href="https://v2.vuetifyjs.com/en/api/v-switch/" target="_blank">https://v2.vuetifyjs.com/en/api/v-switch/</hb-link>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Switch Examples + Code" class="mt-4 mb-6">
            <hb-form label="Switches" full>

                <HbSwitch
                    v-model="switch1"
                    label="Basic switch"
                />

                <HbSwitch
                    v-model="switch2"
                    label="Basic switch disabled"
                    disabled
                />

                <HbSwitch
                    v-model="switch3"
                    label="Basic switch with tooltip"
                    tooltip-header="Tooltip Header"
                    tooltip-body="Tooltip Body"
                />

                <hb-switch v-model="switch4">
                    <template v-slot:label>
                        Basic switch using label slot instead of props
                    </template>
                </hb-switch>
                
                <hb-switch v-model="switch5">
                    <template v-slot:label>
                        Basic switch using label slot and tooltip slots instead of props
                    </template>
                    <template v-slot:tooltipHeader>
                        Tooltip Header
                    </template>
                    <template v-slot:tooltipBody>
                        Tooltip Body
                    </template>
                </hb-switch>
                
                <HbSwitch
                    right
                    v-model="switch6"
                    label="Basic switch on the right side"
                />
                
                <HbSwitch
                    right
                    v-model="switch7"
                    label="Basic switch on the right side with tooltip"
                    tooltip-header="Tooltip Header"
                    tooltip-body="Tooltip Body"
                />

            </hb-form>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbSwitch
    v-model="switch1"
    label="Basic switch"
/>

&lt;HbSwitch
    v-model="switch2"
    label="Basic switch disabled"
    disabled
/>

&lt;HbSwitch
    v-model="switch3"
    label="Basic switch with tooltip"
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
/>

&lt;hb-switch v-model="switch4">
    &lt;template v-slot:label>
        Basic switch using label slot instead of props
    &lt;/template>
&lt;/hb-switch>

&lt;hb-switch v-model="switch5">
    &lt;template v-slot:label>
        Basic switch using label slot and tooltip slots instead of props
    &lt;/template>
    &lt;template v-slot:tooltipHeader>
        Tooltip Header
    &lt;/template>
    &lt;template v-slot:tooltipBody>
        Tooltip Body
    &lt;/template>
&lt;/hb-switch>

&lt;HbSwitch
    right
    v-model="switch6"
    label="Basic switch on the right side"
/>

&lt;HbSwitch
    right
    v-model="switch7"
    label="Basic switch on the right side with tooltip"
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
/>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Available Slots + Code" class="mt-4 mb-6">
            <hb-form label="Slots">
                
                <hb-switch v-model="switch8">
                    <template v-slot:label>
                        v-slot:label
                    </template>
                    <template v-slot:tooltipHeader>
                        v-slot:tooltipHeader
                    </template>
                    <template v-slot:tooltipBody>
                        v-slot:tooltipBody
                    </template>
                </hb-switch>
                
            </hb-form>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-switch v-model="switch8">
    &lt;template v-slot:label>
        v-slot:label
    &lt;/template>
    &lt;template v-slot:tooltipHeader>
        v-slot:tooltipHeader
    &lt;/template>
    &lt;template v-slot:tooltipBody>
        v-slot:tooltipBody
    &lt;/template>
&lt;/hb-switch>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemSwitches",
        data: function() {
            return {
                switch1: false,
                switch2: true,
                switch3: false,
                switch4: true,
                switch5: false,
                switch6: true,
                switch7: false,
                switch8: true,
                propHeaders: [
                    { text: 'Name', value: 'name', width: '140' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'label', type: 'string', default: 'undefined', description: 'Sets the label text to the right of the switch. An alternative way of setting the label text is by using the "v-slot:label" slot which is useful when you need more complex html/content in the label.' },
                    { name: 'tooltip-header', type: 'string', default: 'undefined', description: 'Sets the label tooltip hover-over header text. You can also use the "v-slot:tooltipHeader" slot to set the tooltip header content (useful for when you need to set more than just a basic string like when you need to set custom html.' },
                    { name: 'tooltip-body', type: 'string', default: 'undefined', description: 'Sets the label tooltip hover-over content text.  You can also use the "v-slot:tooltipBody" slot to set the tooltip body content (useful for when you need to set more than just a basic string like when you need to set custom html.' },
                    { name: 'right', type: 'boolean', default: 'false', description: 'If set to true, the label content will move to the left side of the switch and the switch will go on the right side.' }
                    
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'label', description: 'This slot is where you can set the label content.' },
                    { name: 'tooltipHeader', description: 'This slot is where you can set the tooltip header content.' },
                    { name: 'tooltipBody', description: 'This slot is where you can set the tooltip body content.' }
                ]
            };
        },
    }
</script>